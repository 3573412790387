import * as React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import Seo from "../components/seo"
import towerOfBabel from "../images/tower_babel.svg"

const NotFoundPage = () => (
  <Layout>
    <Helmet>
      <meta http-equiv="refresh" content="6; URL=/" />
    </Helmet>
    <Seo title="404: Not found" />
    <article className="page hentry 404">
      <div className="fade-out">
        <img
          src={towerOfBabel}
          height="250"
          width="300"
          className="d-block mx-auto mt-5"
          alt=""
        />
      </div>
      <header className="entry-header">
        <h1 className="entry-title">404: Not Found</h1>
      </header>
      <div className="entry-content">
        <p style={{ textAlign: "center" }}>
          We're sorry. The page you are trying to reach doesn't exist. You will
          be automatically redirected to our homepage shortly.
        </p>
        <div
          style={{ height: "100px" }}
          aria-hidden="true"
          className="wp-block-spacer"
        ></div>
      </div>
    </article>
  </Layout>
)

export default NotFoundPage
